import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Autocomplete,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material'; // MUI components
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  CloudUpload as CloudUploadIcon,
  CameraAlt as CameraAltIcon,
} from '@mui/icons-material'; // MUI icons
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EditAsset = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const [asset, setAsset] = useState(null);
  const [originalAsset, setOriginalAsset] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [sites, setSites] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [employeeResponsibles, setEmployeeResponsibles] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [imageFiles, setImageFiles] = useState({});
  const [uploadedImages, setUploadedImages] = useState({});
  const [cameraOpen, setCameraOpen] = useState(false);
  const [currentImageField, setCurrentImageField] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState('');

  // Hardcoded list of departments
  const departments = [
    'แผนก Factory',
    'แผนก Document Control Center',
    'แผนก QMR',
    'แผนก GA',
    'แผนก MIS&IT',
    'แผนก Shipment Control RJ',
    'แผนก Accounting GL/AP',
    'แผนก Product Development (Beauty)',
    'แผนก Graphic Design',
    'แผนก Research & Development',
    'แผนก Sourcing (Beauty)',
    'แผนก Shipment Control',
    'แผนก Accounting Costing',
    'แผนก Risk Management',
    'แผนก Accounting AR/Inventory',
    'แผนก Logistics',
    'แผนก Demand Planning',
    'แผนก Merchandise Management1',
    'แผนก Talent Acquisition',
    'แผนก Brand Management (Skin care and Personal Care)',
    'แผนก Sales (Equipment and Lifestyle)',
    'แผนก Trade Marketing (Beauty)',
    'แผนก Sales (Skin care and Personal Care)',
    'แผนก Marketing (Lifestyle)',
    'แผนก Brand Management (Lifestyle)',
    'แผนก Field Operation',
    'แผนก HRM',
    'แผนก Visual Merchandise',
    'แผนก Regulatory Affairs',
    'แผนก QA',
    'แผนก Sourcing (Lifestyle)',
    'แผนก Service Center',
    'แผนก Marketing (Beauty)',
    'แผนก Digital Communication',
    'แผนก HRD',
    'แผนก Brand Management (Equipment)',
    'แผนก Trade Marketing (Lifestyle)',
    'แผนก Store Design',
  ];

  // Centralized function to fetch data with token
  const fetchWithToken = async (url, options = {}) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Authentication token is missing.');

    const headers = {
      Authorization: `Bearer ${token}`,
      ...options.headers,
    };

    const response = await fetch(url, { ...options, headers });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch data');
    }
    return await response.json();
  };

  // Function to handle Assign Ownership
  const handleOwnershipAssignment = (value) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      Employee_Responsible: value || '',
      checklist_4: 'Yes',
    }));
  };

  const allowedSuppliers = [
    'บริษัท สุขสวัสดิ์ คอนเวิร์ทติ้ง เซ็นเตอร์ จำกัด',
    'บริษัท อาคเนย์อุตสาหกรรมบรรจุภัณฑ์ จำกัด',
    'บริษัท ไทยอ๊อฟเซท จำกัด',
    'บริษัท ยูนิเวอร์สแพ็ค จำกัด',
    'บริษัท เวิลด์แพคเกจจิ้ง อินดัสตรี้ จำกัด',
    'บริษัท ชริ้งเฟล็กซ์ (ประเทศไทย) จำกัด (มหาชน)',
  ];

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const [
          assetDetail,
          employeesData,
          sitesData,
          employeeResponsiblesData,
          suppliersData,
        ] = await Promise.all([
          fetchWithToken(`https://dbaac.dodaydream.net/api/fixed_assets/${id}`),
          fetchWithToken('https://dbaac.dodaydream.net/api/employees'),
          fetchWithToken('https://dbaac.dodaydream.net/api/sites'),
          fetchWithToken('https://dbaac.dodaydream.net/api/employee_responsibles'),
          fetchWithToken('https://dbaac.dodaydream.net/api/suppliers'),
        ]);

        setAsset(assetDetail);
        setOriginalAsset(assetDetail);
        setEmployees(employeesData);
        setSites(sitesData);
        setEmployeeResponsibles(employeeResponsiblesData);
        setSuppliers(suppliersData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [id]);

  // Fetch sub locations with names based on selected site
  const fetchSubLocationsWithNames = async (site) => {
    if (!site) {
      setSubLocations([]);
      return;
    }
    try {
      const data = await fetchWithToken(
        `https://dbaac.dodaydream.net/api/sub_locations_with_names?site=${site}`
      );
      setSubLocations(data);
    } catch (error) {
      console.error('Error fetching sub locations:', error);
      setSubLocations([]);
    }
  };

  // Handle Site selection change
  const handleSiteChange = async (event, newValue) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      Site: newValue || '',
      Sub_Location: '',
      checklist_1: 'No', // Reset checklist_1 to 'No' when Site changes
    }));
    if (newValue) {
      await fetchSubLocationsWithNames(newValue);
    } else {
      setSubLocations([]);
    }
  };

  // Handle Sub Location selection change
  const handleSubLocationChange = (event, newValue) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      Sub_Location: newValue || '',
      checklist_1: newValue ? 'Yes' : 'No', // Set checklist_1 to 'Yes' only if a Sub Location is selected
    }));
  };

  // Handle input changes for TextFields and Selects
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAsset((prevAsset) => {
      const updatedAsset = { ...prevAsset, [name]: value };

      if (name === 'Status_Tag') {
        updatedAsset.checklist_2 = 'Yes';
      }

      if (name === 'STATUS') {
        updatedAsset.checklist_3 = 'Yes';
      }

      return updatedAsset;
    });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setAsset((prevAsset) => ({
      ...prevAsset,
      [name]: checked ? 'Yes' : 'No',
      // Additional logic for checklist_1 will be handled separately
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      setImageFiles((prevFiles) => ({ ...prevFiles, [name]: file }));
      setUploadedImages((prevImages) => ({
        ...prevImages,
        [name]: URL.createObjectURL(file),
      }));
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('User is not authenticated.');

      const formData = new FormData();
      formData.append('Audit_By', user.employee_id || '');

      const changedFields = {};
      Object.keys(asset).forEach((key) => {
        if (key !== 'last_edit' && key !== 'id') {
          const originalValue = originalAsset[key];
          const currentValue = asset[key];

          if (currentValue !== originalValue) {
            changedFields[key] = currentValue || '';
            formData.append(key, currentValue || '');
          }
        }
      });

      // Handle Check_By_AC and usercheck updates
      if (asset.Check_By_AC !== originalAsset.Check_By_AC) {
        formData.append('Check_By_AC_Changed_By', user.employee_id);
      }

      if (asset.usercheck !== originalAsset.usercheck) {
        formData.append('User_Check_Changed_By', user.employee_id);
      }

      // Include any new image files
      Object.keys(imageFiles).forEach((key) => {
        formData.append(key, imageFiles[key]);
      });

      if (Object.keys(changedFields).length === 0 && Object.keys(imageFiles).length === 0) {
        setSuccess('No changes detected.');
        return;
      }

      const response = await fetch(`https://dbaac.dodaydream.net/api/fixed_assets/${id}`, {
        method: 'PUT',
        headers: {
          'x-access-token': token,
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update asset');
      }

      setSuccess('Asset updated successfully');
      setTimeout(() => navigate(`/asset/${id}`), 1500);
    } catch (error) {
      setError(error.message);
    }
  };

  const readOnlyStyles = {
    backgroundColor: '#f5f5f5',
    color: '#333',
    fontWeight: 'bold',
  };

  const openCameraDialog = (imageField) => {
    setCurrentImageField(imageField);
    setCameraOpen(true);
  };

  const handleCameraCapture = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setImageFiles((prevFiles) => ({ ...prevFiles, [currentImageField]: file }));
    setUploadedImages((prevImages) => ({
      ...prevImages,
      [currentImageField]: URL.createObjectURL(file),
    }));
    setCameraOpen(false);
  };

  const handleOpenPreview = () => {
    setPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setPreviewOpen(false);
  };

  const handleOpenImagePreview = (imageUrl) => {
    setPreviewImageUrl(imageUrl);
    setImagePreviewOpen(true);
  };

  const handleCloseImagePreview = () => {
    setImagePreviewOpen(false);
    setPreviewImageUrl('');
  };

  const getStatusComponents = (status) => {
    if (status === 'Yes') {
      return (
        <Box display="flex" alignItems="center" style={{ color: 'green' }}>
          <CheckCircleIcon style={{ marginRight: '4px' }} />
          <Typography variant="body2">YES</Typography>
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="center" style={{ color: 'red' }}>
          <CancelIcon style={{ marginRight: '4px' }} />
          <Typography variant="body2">NO</Typography>
        </Box>
      );
    }
  };

  const renderImageReadOnly = (label, fieldName) => {
    const imageUrl = asset[fieldName]
      ? `https://dbaac.dodaydream.net/images/assets/${asset[fieldName]}`
      : 'https://dbaac.dodaydream.net/images/placeholder.png';
    return (
      <Box mt={2} key={fieldName}>
        <InputLabel>{`${label}`}</InputLabel>
        <Box display="flex" gap={2} alignItems="center">
          <img
            src={imageUrl}
            alt={label}
            style={{
              width: '150px',
              height: '150px',
              objectFit: 'cover',
              marginRight: '16px',
              cursor: 'pointer',
            }}
            onClick={() => handleOpenImagePreview(imageUrl)}
          />
        </Box>
      </Box>
    );
  };

  const renderImage = (label, fieldName) => {
    const imageUrl =
      uploadedImages[fieldName] ||
      (asset[fieldName]
        ? `https://dbaac.dodaydream.net/images/assets/${asset[fieldName]}`
        : 'https://dbaac.dodaydream.net/images/placeholder.png');
    return (
      <Box mt={2} key={fieldName}>
        <InputLabel>{`${label}`}</InputLabel>
        <Box display="flex" gap={2} alignItems="center">
          <img
            src={imageUrl}
            alt={label}
            style={{
              width: '150px',
              height: '150px',
              objectFit: 'cover',
              marginRight: '16px',
              cursor: 'pointer',
            }}
            onClick={() => handleOpenImagePreview(imageUrl)}
          />
          <input
            accept="image/*"
            id={`upload-${fieldName}`}
            name={fieldName}
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <label htmlFor={`upload-${fieldName}`}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </label>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CameraAltIcon />}
            onClick={() => openCameraDialog(fieldName)}
          >
            Capture
          </Button>
        </Box>
      </Box>
    );
  };

  // Render usercheck and Check_By_AC fields with visibility based on user roles
  const renderUserCheckAndCheckByAC = () => {
    return (
      <>
        {/* Render usercheck field visible only to users with 'User' role */}
        {user.role === 'User' && (
          <FormControl fullWidth margin="normal">
            <InputLabel>User Check</InputLabel>
            <Select
              label="User Check"
              name="usercheck"
              value={asset.usercheck || ''}
              onChange={handleInputChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        )}

        {/* Check_By_AC field visible only for Superadmin and Acc roles */}
        {(user.role === 'Superadmin' || user.role === 'Acc') && (
          <FormControl fullWidth margin="normal">
            <InputLabel>Check By AC</InputLabel>
            <Select
              label="Check By AC"
              name="Check_By_AC"
              value={asset.Check_By_AC || ''}
              onChange={handleInputChange}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        )}
      </>
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <Alert severity="error">{error}</Alert>;

  // Combine employees and departments for the Autocomplete options
  const combinedOptions = [...employeeResponsibles, ...departments];

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h4" gutterBottom>
        Edit Asset
      </Typography>

      {success && <Alert severity="success">{success}</Alert>}

      {asset && (
        <form noValidate autoComplete="off">
          <Grid container spacing={2}>
            {/* Left Column */}
            <Grid item xs={12} md={6}>
              {/* Read-Only Fields */}
              <TextField
                label="Inventory ID"
                value={asset.Inventory_ID}
                InputProps={{ readOnly: true }}
                fullWidth
                margin="normal"
                style={readOnlyStyles}
              />

              <TextField
                label="Asset Name"
                name="Assetname"
                value={asset.Assetname || ''}
                margin="normal"
                InputProps={{ readOnly: true }}
                fullWidth
                style={readOnlyStyles}
              />

              {/* <TextField
                label="Department Name"
                name="Departmentname"
                value={asset.Departmentname || ''}
                margin="normal"
                InputProps={{ readOnly: true }}
                fullWidth
                style={readOnlyStyles}
              /> */}

              <TextField
                label="Employee/Department Responsible"
                name="Employee/Department Responsible"
                value={asset.Employee_Responsible || ''}
                margin="normal"
                InputProps={{ readOnly: true }}
                fullWidth
                style={readOnlyStyles}
              />

              <TextField
                label="Serial No"
                name="Serial_No"
                value={asset.Serial_No || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Note"
                name="Note"
                value={asset.Note || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
  <Typography variant="h5">Picture ใหม่สำหรับ ปี 2024</Typography>
              {/* Image Uploads */}
              {renderImage('Picture 11', 'Picture_11')}
              {renderImage('Picture 21', 'Picture_21')}
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={6}>
              {/* Checklist Section */}
              <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
                CheckList
              </Typography>
              <Grid container spacing={2}>
                <Divider style={{ margin: '16px 0' }} />
                {/* Checklist 1 */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={asset.checklist_1 === 'Yes'}
                        onChange={handleSwitchChange}
                        name="checklist_1"
                        color="primary"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" width="100%">
                        {asset.checklist_1 === 'Yes' ? (
                          <>
                            <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'green' }}>
                              Yes
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelIcon style={{ color: 'red', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'red' }}>
                              No
                            </Typography>
                          </>
                        )}
                        <Box ml={2}>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            1. ระบุ ความถูกต้องของ สถานที่ตั้งทรัพย์สิน:
                          </Typography>
                          <Typography variant="body2">
                            (1) ถ้าสถานที่ตั้ง ตรงกับ Master ให้ขึ้นเป็น "YES"
                          </Typography>
                          <Typography variant="body2">
                            (2) ถ้าสถานที่ตั้ง ไม่ตรงกับ Master ให้ขึ้นเป็น "NO"
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />

                  {/* Site Field */}
                  {asset.checklist_1 === 'No' ? (
                    <Autocomplete
                      options={sites}
                      getOptionLabel={(option) => option || ''}
                      value={asset.Site || ''}
                      onChange={handleSiteChange}
                      renderInput={(params) => (
                        <TextField {...params} label="Site" margin="normal" fullWidth />
                      )}
                    />
                  ) : (
                    <Box display="flex" alignItems="center" mt={1}>
                      {getStatusComponents(asset.checklist_1)}
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        Site: {asset.Site || 'N/A'}
                      </Typography>
                    </Box>
                  )}

                  {/* Sub Location Field */}
                  {asset.checklist_1 === 'No' ? (
                    subLocations.length > 0 ? (
                      <Autocomplete
                        options={subLocations}
                        getOptionLabel={(option) => option || ''}
                        value={asset.Sub_Location || ''}
                        onChange={handleSubLocationChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Sub Location + Sub Loc Name"
                            margin="normal"
                            fullWidth
                          />
                        )}
                      />
                    ) : (
                      <Alert severity="warning">
                        No Sub Locations found for the selected Site.
                      </Alert>
                    )
                  ) : (
                    <Box display="flex" alignItems="center" mt={1}>
                      {getStatusComponents(asset.checklist_1)}
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        Sub Location: {asset.Sub_Location || 'N/A'}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Divider style={{ margin: '16px 0' }} />

                {/* Checklist 2 */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={asset.checklist_2 === 'Yes'}
                        onChange={handleSwitchChange}
                        name="checklist_2"
                        color="primary"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" width="100%">
                        {asset.checklist_2 === 'Yes' ? (
                          <>
                            <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'green' }}>
                              Yes
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelIcon style={{ color: 'red', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'red' }}>
                              No
                            </Typography>
                          </>
                        )}
                        <Box ml={2}>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            2. ระบุความสมบูรณ์ของป้ายแสดงรหัสทรัพย์สิน:
                          </Typography>
                          <Typography variant="body2">
                            (1) ถ้าความสมบูรณ์ของป้าย ให้เลือกเป็น "YES"
                          </Typography>
                          <Typography variant="body2">
                            (2) ถ้าความสมบูรณ์ของป้าย ไม่ถูกต้อง ให้เลือกเป็น "NO"
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />

                  {/* Status Tag */}
                  {asset.checklist_2 === 'No' ? (
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Status Tag</InputLabel>
                      <Select
                        label="Status Tag"
                        name="Status_Tag"
                        value={asset.Status_Tag || ''}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="ปกติ">ปกติ</MenuItem>
                        <MenuItem value="ชำรุด">ชำรุด/สูญหาย</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <Box display="flex" alignItems="center" mt={1}>
                      {getStatusComponents(asset.checklist_2)}
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        Status Tag: {asset.Status_Tag || 'N/A'}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Divider style={{ margin: '16px 0' }} />

                {/* Checklist 3 */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={asset.checklist_3 === 'Yes'}
                        onChange={handleSwitchChange}
                        name="checklist_3"
                        color="primary"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" width="100%">
                        {asset.checklist_3 === 'Yes' ? (
                          <>
                            <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'green' }}>
                              Yes
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelIcon style={{ color: 'red', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'red' }}>
                              No
                            </Typography>
                          </>
                        )}
                        <Box ml={2}>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            3. สถานะการใช้งานทรัพย์สิน:
                          </Typography>
                          <Typography variant="body2">
                            (1) ถ้าสถานะการใช้งานทรัพย์สิน ถูกต้องแล้วให้ เลือก "YES"
                          </Typography>
                          <Typography variant="body2">
                            (2) ถ้าสถานะการใช้งานทรัพย์สิน มีปัญหาให้เลือก "NO"
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />

                  {/* Status Asset */}
                  {asset.checklist_3 === 'No' ? (
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Status Asset</InputLabel>
                      <Select
                        label="Status Asset"
                        name="STATUS"
                        value={asset.STATUS || ''}
                        onChange={handleInputChange}
                      >
                        <MenuItem value="ใช้งานปกติ">ใช้งานปกติ</MenuItem>
                        <MenuItem value="ไม่ได้ใช้งาน">ไม่ได้ใช้งาน</MenuItem>
                        <MenuItem value="ชำรุด/เสื่อมสภาพ">ชำรุด/เสื่อมสภาพ</MenuItem>
                        <MenuItem value="ไม่พบทรัพย์สิน">ไม่พบทรัพย์สิน</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    <Box display="flex" alignItems="center" mt={1}>
                      {getStatusComponents(asset.checklist_3)}
                      <Typography variant="body2" style={{ marginLeft: '8px' }}>
                        Status: {asset.STATUS || 'N/A'}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Divider style={{ margin: '16px 0' }} />

                {/* Checklist 4 */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={asset.checklist_4 === 'Yes'}
                        onChange={handleSwitchChange}
                        name="checklist_4"
                        color="primary"
                      />
                    }
                    label={
                      <Box display="flex" alignItems="center" width="100%">
                        {asset.checklist_4 === 'Yes' ? (
                          <>
                            <CheckCircleIcon style={{ color: 'green', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'green' }}>
                              Yes
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelIcon style={{ color: 'red', marginRight: '8px' }} />
                            <Typography variant="body2" style={{ color: 'red' }}>
                              No
                            </Typography>
                          </>
                        )}
                        <Box ml={2}>
                          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                            4. ชื่อผู้ครอบครองทรัพย์สิน ได้ Assign ให้ ผู้รับผิดชอบแล้ว:
                          </Typography>
                          <Typography variant="body2">
                            (1) ถ้าชื่อผู้ครอบครองทรัพย์สิน ได้ Assign ให้ ผู้รับผิดชอบแล้ว เลือก "YES"
                          </Typography>
                          <Typography variant="body2">
                            (2) ถ้าไม่สามารถระบุชื่อผู้ครอบครองทรัพย์สิน ได้ให้ เลือก "NO"
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />
                </Grid>
              </Grid>

              {/* Assign Ownership Section */}
              {asset.checklist_4 === 'No' && (
                <Box mt={2}>
                  <Typography variant="h6">Assign Ownership</Typography>

                  <Autocomplete
                    options={combinedOptions}
                    getOptionLabel={(option) => option || ''}
                    value={asset.Employee_Responsible || ''}
                    onChange={(event, newValue) => handleOwnershipAssignment(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Employee/Department Responsible"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  />

                  {/* Optionally, if you need to handle suppliers separately */}
                  <Autocomplete
                    options={allowedSuppliers}
                    getOptionLabel={(option) => option || ''}
                    value={asset.Supplier || ''}
                    onChange={(event, newValue) =>
                      setAsset((prevAsset) => ({ ...prevAsset, Supplier: newValue || '' }))
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Supplier" margin="normal" fullWidth />
                    )}
                  />
                </Box>
              )}
              <Divider style={{ margin: '36px 0' }} />
              {/* Render usercheck and Check_By_AC */}
              {renderUserCheckAndCheckByAC()}

              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleOpenPreview}
                  style={{ marginRight: '8px' }}
                >
                  Preview Changes
                </Button>
                <Button variant="contained" color="success" onClick={handleSave}>
                  Save Changes
                </Button>
              </Box>
              <Divider style={{ margin: '36px 0' }} />
            </Grid>
          </Grid>
        </form>
      )}
      <Divider style={{ margin: '36px 0' }} />
      {/* Dialog for capturing images */}
      <Dialog open={cameraOpen} onClose={() => setCameraOpen(false)}>
        <DialogTitle>Take a Picture</DialogTitle>
        <DialogContent>
          <input
            accept="image/*"
            type="file"
            capture="environment"
            onChange={handleCameraCapture}
            style={{ display: 'none' }}
            id="camera-input"
          />
          <label htmlFor="camera-input">
            <Button variant="contained" color="primary" component="span">
              Capture Image
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCameraOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog open={previewOpen} onClose={handleClosePreview} maxWidth="md" fullWidth>
        <DialogTitle>Preview Changes</DialogTitle>
        <DialogContent dividers>
          {/* คุณสามารถเพิ่มข้อมูลที่ต้องการแสดงใน preview ได้ที่นี่ */}
          <Typography variant="body1">Preview of the changes goes here.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePreview} color="error">
            Close
          </Button>
          <Button onClick={handleSave} color="success" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for full-size image preview */}
      <Dialog open={imagePreviewOpen} onClose={handleCloseImagePreview} maxWidth="lg" fullWidth>
        <DialogContent>
          <img
            src={previewImageUrl}
            alt="Full Preview"
            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImagePreview} color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default EditAsset;
