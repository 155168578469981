import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Paper, Typography, Grid, Button, Table, TableHead, TableRow, TableCell, TableBody, Collapse, IconButton, ImageList, ImageListItem, Modal, Box } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

const AssetDetail = () => {
  const { id } = useParams();
  const [asset, setAsset] = useState(null);
  const [auditUser, setAuditUser] = useState(null);
  const [assignedUser, setAssignedUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);
  const [updatedByUser, setUpdatedByUser] = useState(null);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [open, setOpen] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchAssetDetail = async () => {
      try {
        // Fetch asset detail
        const response = await fetch(`https://dbaac.dodaydream.net/api/fixed_assets/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) throw new Error('Failed to fetch asset details');
        const data = await response.json();
        setAsset(data);

        // Fetch all companies and find the matching one
        const companyResponse = await fetch(`https://dbaac.dodaydream.net/api/companies`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (companyResponse.ok) {
          const companiesData = await companyResponse.json();
          const matchedCompany = companiesData.find(c => c.company_id === data.Company);
          setCompany(matchedCompany || null);
        } else {
          setCompany(null);
        }

        // Fetch audit user (if exists)
        if (data.Audit_By) {
          const userResponse = await fetch(`https://dbaac.dodaydream.net/api/employees/${data.Audit_By}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (userResponse.status === 404) {
            console.warn(`Audit user with ID ${data.Audit_By} not found.`);
            setAuditUser({ firstname_th: 'Unknown', lastname_th: 'User' });
          } else if (userResponse.ok) {
            const userData = await userResponse.json();
            setAuditUser(userData);
          } else {
            setAuditUser({ firstname_th: 'Unknown', lastname_th: 'User' });
          }
        }

        // Fetch assigned user details
        if (data.assign_to) {
          const assignedUserResponse = await fetch(`https://dbaac.dodaydream.net/api/employees/${data.assign_to}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (assignedUserResponse.ok) {
            const assignedUserData = await assignedUserResponse.json();
            setAssignedUser(assignedUserData);
          } else {
            setAssignedUser(null);
          }
        } else {
          setAssignedUser(null);
        }

        // Fetch asset change history
        const historyResponse = await fetch(`https://dbaac.dodaydream.net/api/asset_history/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (historyResponse.ok) {
          let historyData = await historyResponse.json();
          const usersMap = {};

          // Fetch employee details for each updated_by in history
          for (const log of historyData) {
            if (log.updated_by && !usersMap[log.updated_by]) {
              try {
                const userResponse = await fetch(`https://dbaac.dodaydream.net/api/employees/${log.updated_by}`, {
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                });
                if (userResponse.status === 404) {
                  console.warn(`User with ID ${log.updated_by} not found.`);
                  usersMap[log.updated_by] = 'Unknown User';
                } else if (userResponse.ok) {
                  const userData = await userResponse.json();
                  usersMap[log.updated_by] = `${userData.firstname_th} ${userData.lastname_th}`;
                } else {
                  usersMap[log.updated_by] = 'Unknown User';
                }
              } catch (err) {
                usersMap[log.updated_by] = 'Unknown User';
              }
            }
            // Check if the field is 'assign_to' and replace ID with the employee name
            if (log.field === 'assign_to') {
              try {
                const assignToResponse = await fetch(`https://dbaac.dodaydream.net/api/employees/${log.new_value}`, {
                  headers: {
                    'Authorization': `Bearer ${token}`
                  }
                });
                if (assignToResponse.ok) {
                  const assignToData = await assignToResponse.json();
                  log.new_value = `${assignToData.firstname_th} ${assignToData.lastname_th}`;
                } else {
                  log.new_value = 'Unknown Employee';
                }
              } catch (err) {
                log.new_value = 'Unknown Employee';
              }
            }
            log.updated_by = usersMap[log.updated_by] || 'Unknown User';
          }

          // Filter irrelevant changes and set history state
          historyData = historyData.filter(log => log.field !== 'id' && log.field !== 'last_edit');
          setHistory(historyData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))); // Sort newest first

          // Set Updated By and Updated At if history is available
          if (historyData.length > 0) {
            setUpdatedByUser(historyData[0].updated_by);
            setUpdatedAt(historyData[0].updated_at);
          }
        } else {
          console.error('Failed to fetch asset history');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAssetDetail();
  }, [id, token]);

  const handleEditClick = () => {
    navigate(`/edit/${id}`);
  };

  const toggleRow = (date) => {
    setOpen(prevOpen => ({
      ...prevOpen,
      [date]: !prevOpen[date]
    }));
  };

  const groupHistoryByTimestamp = (history) => {
    const groupedHistory = {};
    history.forEach((log) => {
      const date = new Date(log.updated_at);
      if (!isNaN(date.getTime())) {
        const updatedDate = date.toISOString().slice(0, 16); // Group by precise minute
        if (!groupedHistory[updatedDate]) {
          groupedHistory[updatedDate] = [];
        }
        groupedHistory[updatedDate].push(log);
      }
    });
    return groupedHistory;
  };

  // Helper function to format date and time with UTC+7 (Bangkok) adjustment
  const formatDateTime = (timestamp) => {
    if (!timestamp) return '-';

    const date = new Date(`${timestamp}Z`);
    if (isNaN(date.getTime())) return '-';

    const thailandOffset = 7 * 60; // UTC+7 in minutes
    const localTime = new Date(date.getTime() + thailandOffset * 60 * 1000);

    const day = String(localTime.getUTCDate()).padStart(2, '0');
    const month = String(localTime.getUTCMonth() + 1).padStart(2, '0');
    const year = localTime.getUTCFullYear();
    const hours = String(localTime.getUTCHours()).padStart(2, '0');
    const minutes = String(localTime.getUTCMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  // Render images in Standard Image List with click functionality to open in modal
  const renderImageList = () => {
    // Fields for last year
    const lastYearImageFields = ['Picture_1', 'Picture_2'];
    // Fields for current year
    const currentYearImageFields = ['Picture_11', 'Picture_21'];

    const handleImageClick = (imageUrl) => {
      setSelectedImage(imageUrl);
      setModalOpen(true);
    };

    return (
      <>
        {/* Section for Picture years[now-1], disable editing */}
        <Typography variant="h6" gutterBottom>
          Picture years[{new Date().getFullYear() - 1}]
        </Typography>
        <ImageList cols={2} gap={8}>
          {lastYearImageFields.map((field, index) => (
            asset[field] && (
              <ImageListItem key={index}>
                <img
                  src={`https://dbaac.dodaydream.net/images/assets/${asset[field]}`}
                  alt={`Asset image ${index + 1}`}
                  style={{ width: '320px', height: '240px', objectFit: 'cover' }}
                  onClick={() => handleImageClick(`https://dbaac.dodaydream.net/images/assets/${asset[field]}`)}
                  onError={(e) => { e.target.src = 'https://dbaac.dodaydream.net/images/placeholder.png'; }}
                />
              </ImageListItem>
            )
          ))}
        </ImageList>

        {/* Section for Picture years[now], enable editing */}
        <Typography variant="h6" gutterBottom>
          Picture years[{new Date().getFullYear()}]
        </Typography>
        <ImageList cols={2} gap={8}>
          {currentYearImageFields.map((field, index) => (
            asset[field] && (
              <ImageListItem key={index}>
                <img
                  src={`https://dbaac.dodaydream.net/images/assets/${asset[field]}`}
                  alt={`Asset image ${index + 1}`}
                  style={{ width: '320px', height: '240px', objectFit: 'cover' }}
                  onClick={() => handleImageClick(`https://dbaac.dodaydream.net/images/assets/${asset[field]}`)}
                  onError={(e) => { e.target.src = 'https://dbaac.dodaydream.net/images/placeholder.png'; }}
                />
              </ImageListItem>
            )
          ))}
        </ImageList>
      </>
    );
  };

  const renderHistoryTable = () => {
    if (!history.length) return <Typography>No change history available.</Typography>;

    const groupedHistory = groupHistoryByTimestamp(history);

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Updated At</TableCell>
            <TableCell>Updated By</TableCell>
            <TableCell>Changes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(groupedHistory).map((updatedDate) => {
            const logs = groupedHistory[updatedDate];
            const changeCount = logs.length;

            return (
              <React.Fragment key={updatedDate}>
                <TableRow onClick={() => toggleRow(updatedDate)} style={{ cursor: 'pointer' }}>
                  <TableCell>{formatDateTime(updatedDate)}</TableCell>
                  <TableCell>{logs[0].updated_by}</TableCell>
                  <TableCell>{changeCount} {changeCount > 1 ? 'Changes' : 'Change'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open[updatedDate]} timeout="auto" unmountOnExit>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell>Old Value</TableCell>
                            <TableCell>New Value</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {logs.map((log, index) => (
                            <TableRow key={index}>
                              <TableCell>{log.field}</TableCell>
                              <TableCell>
                                {log.field === 'usercheck' || log.field === 'sitecheck'
                                  ? log.old_value === '1' ? 'Yes' : 'No'
                                  : log.old_value || '-'}
                              </TableCell>
                              <TableCell>
                                {log.field === 'usercheck' || log.field === 'sitecheck'
                                  ? log.new_value === '1' ? 'Yes' : 'No'
                                  : log.new_value || '-'}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedImage('');
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!asset) return <p>No asset data available</p>;

  return (
    <Paper style={{ padding: '6px', margin: '16px' }}>
      <Typography variant="h4" gutterBottom>Asset Detail</Typography>
      <Button variant="contained" color="primary" onClick={handleEditClick} style={{ marginTop: '16px' }}>
        Edit
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1"><strong>Inventory ID:</strong> {asset.Inventory_ID || '-'}</Typography>
          <Typography variant="body1"><strong>Inventory ID OLD:</strong> {asset.Inventory_ID_OLD === 'nan' ? '-' : asset.Inventory_ID_OLD}</Typography>
          {/* <Typography variant="body1"><strong>Fixed Asset ID:</strong> {parseInt(asset.Fixed_Asset_ID, 10) || '-'}</Typography>
          <Typography variant="body1"><strong>Individual Material:</strong> {asset.Individual_Material || '-'}</Typography>
          <Typography variant="body1"><strong>Capitalization Date:</strong> {asset.Capitalization_Date || '-'}</Typography>
          <Typography variant="body1"><strong>Useful Life Years:</strong> {asset.Useful_Life_Years || '-'}</Typography>
          <Typography variant="body1"><strong>Warranty Expiry Date:</strong> {asset.Warranty_Expiry_Date || '-'}</Typography>
          <Typography variant="body1"><strong>Fixed Asset Life Cycle Status:</strong> {asset.Fixed_Asset_Life_Cycle_Status || '-'}</Typography> */}
          <Typography variant="body1"><strong>Company:</strong> {company ? company.orgname : '-'}</Typography>
          <Typography variant="body1"><strong>Site:</strong> {asset.Site || '-'}</Typography>
          <Typography variant="body1"><strong>Sub Location:</strong> {asset.Sub_Location || '-'}</Typography>
          <Typography variant="body1"><strong>Sub Loc Name:</strong> {asset.Sub_Loc_Name || '-'}</Typography>
          <Typography variant="body1"><strong>Fixed Asset Class:</strong> {asset.Fixed_Asset_Class || '-'}</Typography>
          <Typography variant="body1"><strong>Class Name:</strong> {asset.Fixed_asset_classname || '-'}</Typography>        
          <Typography variant="body1"><strong>Asset Name:</strong> {asset.Assetname || '-'}</Typography>
          {/* <Typography variant="body1"><strong>Qty:</strong> {asset.Qty || '-'}</Typography>
          <Typography variant="body1"><strong>Serial No:</strong> {asset.Serial_No || '-'}</Typography>
          <Typography variant="body1"><strong>Status:</strong> {asset.STATUS || '-'}</Typography>
          <Typography variant="body1"><strong>Status Tag:</strong> {asset.Status_Tag || '-'}</Typography>
          <Typography variant="body1"><strong>Historical Cost:</strong> {asset.Historical_Cost || '-'}</Typography>
          <Typography variant="body1"><strong>Accumulated DP:</strong> {asset.Accumulated_DP || '-'}</Typography>
          <Typography variant="body1"><strong>NBV:</strong> {asset.NBV || '-'}</Typography>

          <Typography variant="body1"><strong>Note:</strong> {asset.Note || '-'}</Typography> */}
          <Typography variant="body1"><strong>Department Responsible:</strong> {asset.Department_Responsible || '-'}</Typography>
          <Typography variant="body1"><strong>Department Name:</strong> {asset.Departmentname || '-'}</Typography>
          <Typography variant="body1"><strong>Employee Responsible:</strong> {asset.Employee_Responsible || '-'}</Typography>

          {/* Render Check_By_AC and usercheck along with employee ID of who changed them */}
          {/* <Typography variant="body1"><strong>Check By AC:</strong> {asset.Check_By_AC || '-'} (Changed by: {asset.Check_By_AC_Changed_By || 'N/A'})</Typography>
          <Typography variant="body1"><strong>User Check:</strong> {asset.usercheck || '-'} (Changed by: {asset.User_Check_Changed_By || 'N/A'})</Typography>

          <Typography variant="body1"><strong>Updated By:</strong> {updatedByUser || 'ยังไม่มีผู้ตรวจสอบ'}</Typography> */}

          
        </Grid>
        <Grid item xs={12} md={6}>
          {/* Render Image List */}
          {renderImageList()}
          {/* <Typography variant="h5" gutterBottom>ข้อมูลผู้ถือครอง</Typography> */}

          {/* Conditionally render assigned user information
          {assignedUser ? (
            <>
              <Typography variant="body1"><strong>Assigned To:</strong> {assignedUser.firstname_th} {assignedUser.lastname_th}</Typography>
              <Typography variant="body1"><strong>DIVI:</strong> {assignedUser.DIVI || '-'}</Typography>
              <Typography variant="body1"><strong>FUNC:</strong> {assignedUser.FUNC || '-'}</Typography>
              <Typography variant="body1"><strong>SECT:</strong> {assignedUser.SECT || '-'}</Typography>
              <Typography variant="body1"><strong>Subteam:</strong> {assignedUser.Subteam || '-'}</Typography>
              <Typography variant="body1"><strong>Subteam1:</strong> {assignedUser.Subteam1 || '-'}</Typography>
              <Typography variant="body1"><strong>TEAM:</strong> {assignedUser.TEAM || '-'}</Typography>
              <Typography variant="body1"><strong>Position:</strong> {assignedUser.position || '-'}</Typography>
              <Typography variant="body1"><strong>Company Org:</strong> {assignedUser.company_org || '-'}</Typography>
              <Typography variant="body1"><strong>Company Org Name:</strong> {assignedUser.company_orgname || '-'}</Typography>
              <Typography variant="body1"><strong>Company Org Name (TH):</strong> {assignedUser.company_orgname_th || '-'}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {assignedUser.email || '-'}</Typography>
            </>
          ) : (
            <Typography variant="body1"><strong>Assigned To:</strong> ยังไม่ถูกถือครอง</Typography>
          )} */}
        </Grid>
      </Grid>



      <Typography variant="h5" style={{ marginTop: '32px' }}>Asset Change History</Typography>
      {renderHistoryTable()} 

       Modal for viewing full-size images
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            outline: 'none'
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '8px', right: '8px' }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
          <img src={selectedImage} alt="Full-size" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
        </Box>
      </Modal>
    </Paper>
  );
};

export default AssetDetail;
